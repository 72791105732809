// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-how-graphql-replaces-redux-mdx": () => import("./../src/pages/blog/how-graphql-replaces-redux.mdx" /* webpackChunkName: "component---src-pages-blog-how-graphql-replaces-redux-mdx" */),
  "component---src-pages-blog-how-i-launched-six-side-projects-in-2017-mdx": () => import("./../src/pages/blog/how-i-launched-six-side-projects-in-2017.mdx" /* webpackChunkName: "component---src-pages-blog-how-i-launched-six-side-projects-in-2017-mdx" */),
  "component---src-pages-blog-my-own-place-mdx": () => import("./../src/pages/blog/my-own-place.mdx" /* webpackChunkName: "component---src-pages-blog-my-own-place-mdx" */),
  "component---src-pages-blog-sex-drugs-and-side-projects-mdx": () => import("./../src/pages/blog/sex-drugs-and-side-projects.mdx" /* webpackChunkName: "component---src-pages-blog-sex-drugs-and-side-projects-mdx" */),
  "component---src-pages-drafts-work-and-learning-at-home-mdx": () => import("./../src/pages/drafts/work-and-learning-at-home.mdx" /* webpackChunkName: "component---src-pages-drafts-work-and-learning-at-home-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-transcript-mdx": () => import("./../src/pages/transcript.mdx" /* webpackChunkName: "component---src-pages-transcript-mdx" */),
  "component---src-pages-work-tiltmaps-mdx": () => import("./../src/pages/work/tiltmaps.mdx" /* webpackChunkName: "component---src-pages-work-tiltmaps-mdx" */)
}

